import { Loader, Text, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Navigate, useParams } from "react-router-dom";
import PreviousPageAnchor from "../../components/anchors/PreviousPageAnchor";
import SchoolClassDetailsTable from "../../components/tables/SchoolClassDetailTable";
import useSchoolClassData from "../../hooks/school-class/useSchoolClassData";

export default function SchoolClassDetails() {
  const { schoolClassId = "" } = useParams<{ schoolClassId: string }>();
  const {
    data: schoolClass,
    isFetching,
    isError,
  } = useSchoolClassData(schoolClassId);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    showNotification({
      title: "Er ging iets mis",
      message:
        "Er is een fout opgetreden bij het ophalen van de klas. Je bent teruggestuurd naar het leerlingenoverzicht.",
      autoClose: 10000,
      color: "red",
    });

    return <Navigate to={"/students"} />;
  }

  return (
    <>
      <PreviousPageAnchor />
      <Title>{schoolClass.name}</Title>
      <Text c={"dimmed"} size={"xs"}>
        Schooljaar {schoolClass?.school_year?.name}{" "}
        {`(${!schoolClass?.school_year?.active ? "niet actief" : "actief"})`}
      </Text>
      <SchoolClassDetailsTable
        schoolClassId={schoolClassId}
        schoolYearId={schoolClass?.school_year?.id}
      />
    </>
  );
}
