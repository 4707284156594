import React, { useContext } from "react";
import { SchoolClass, Student } from "../../../types";
import { fetchStudents } from "../../api/students";
import useDeleteStudent from "../../hooks/student/useDeleteStudent";
import useEditStudent from "../../hooks/student/useEditStudent";
import { UserContext } from "../../providers/user";
import { readableDateTime } from "../../tools";
import CreateReportButton from "../buttons/CreateReportButton";
import ViewReportButton from "../buttons/ViewReportButton";
import TableCellText from "../TableCellText";
import TableHeaderText from "../TableHeaderText";
import FancyTable from "./FancyTable";

type SchoolClassDetailsTableProps = {
  schoolClassId: string;
  schoolYearId?: string;
};

export default function SchoolClassDetailsTable(
  props: SchoolClassDetailsTableProps
) {
  const { schoolClassId, schoolYearId } = props;
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteStudent();
  const { openModal: openEditModal } = useEditStudent();

  const filters = [
    {
      column: "school_class_id",
      value: schoolClassId,
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        id: "student-table",
        columns: [
          {
            id: "first_name",
            Header: <TableHeaderText text="Voornaam" />,
            accessor: (d: Student) => (
              <TableCellText
                text={d.first_name}
                subtext={`Laatste bewerking door ${
                  d.updated_by?.name
                } op ${readableDateTime(d.updated_at)}`}
              />
            ),
          },
          {
            id: "last_name",
            Header: <TableHeaderText text="Achternaam" />,
            accessor: (d: Student) => <TableCellText text={d.full_last_name} />,
          },
          {
            id: "period_1_report",
            Header: <TableHeaderText text="Verslag periode 1" />,
            disableSortBy: true,
            accessor: (d: Student) => {
              let report = schoolYearId
                ? d.reports?.find(
                    (r) => r.school_class?.school_year?.id === schoolYearId
                  )
                : d.active_report;
              const fullName = d.full_name;
              const year =
                d.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return report ? (
                <ViewReportButton
                  report={report}
                  period={1}
                  fullName={fullName}
                  year={year}
                />
              ) : (
                <CreateReportButton
                  studentId={d.id}
                  period={1}
                  fullName={fullName}
                  year={year}
                />
              );
            },
          },
          ,
          {
            id: "period_2_report",
            Header: <TableHeaderText text="Verslag periode 2" />,
            disableSortBy: true,
            accessor: (d: Student) => {
              const fullName = d.full_name;
              const year =
                d.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return (
                d.active_report && (
                  <ViewReportButton
                    report={d.active_report}
                    period={2}
                    fullName={fullName}
                    year={year}
                  />
                )
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <FancyTable
        queryKey={"students"}
        queryFn={fetchStudents}
        columns={columns}
        onDelete={isAuthorized("student_destroy") ? openDeleteModal : undefined}
        onEdit={isAuthorized("student_update") ? openEditModal : undefined}
        filters={filters}
        queryPageSize={100}
      />
    </>
  );
}
